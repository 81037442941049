import { SUPPORTED_LOCALES, SupportedLocale } from '../../../i18n/constants';

export const isSupportedLocale = (input: string): input is SupportedLocale =>
  SUPPORTED_LOCALES.includes(input as SupportedLocale);

export function userLanguage(
  languageCode: string,
  pointOfSale: string
): SupportedLocale {
  const languageCodeLowerCase = languageCode.slice(0, 2).toLowerCase();
  const pointOfSaleLowerCase = pointOfSale.toLowerCase();
  const regionalLocale = `${languageCodeLowerCase}-${pointOfSaleLowerCase}`;

  if (isSupportedLocale(regionalLocale)) {
    return regionalLocale;
  } else if (isSupportedLocale(languageCodeLowerCase)) {
    return languageCodeLowerCase;
  }
  const firstCodeMatch = SUPPORTED_LOCALES.find((locale) =>
    locale.startsWith(languageCode)
  );
  if (languageCode && firstCodeMatch) {
    return firstCodeMatch;
  }
  return SupportedLocale.EN;
}
