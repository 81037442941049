export enum SupportedLocale {
  AR = 'ar',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  ID = 'id',
  JA = 'ja',
  KM_KH = 'km-kh',
  KO = 'ko',
  MS = 'ms',
  RU = 'ru',
  TH = 'th',
  TR = 'tr',
  VI = 'vi',
  ZH_HANS = 'zh-hans',
  ZH_HANT = 'zh-hant',
}

export const SUPPORTED_LOCALES = Object.values(SupportedLocale);
export const DEFAULT_NAMESPACE = 'common';
export const DEFAULT_LNG = SupportedLocale.EN;

const localeForKey = (key: SupportedLocale) =>
  import(`../../../../node_modules/date-fns/locale/${key}.js`);

export const DATE_FNS_LOCALES = Object.values(SupportedLocale).reduce(
  (acc, key) => ({
    ...acc,
    [key]: () => localeForKey(key),
  }),
  {} as Record<SupportedLocale, () => Promise<typeof import('date-fns/locale')>>
);

// en isn't defined in date-fns locales without a region
DATE_FNS_LOCALES[DEFAULT_LNG] = () => localeForKey('en-US');
