import {
  Box,
  Button,
  FormLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { UseMutationResult, useSuspenseQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { sessionOptions } from '../../products/cfar/queries/sessionOptions';
import { useCfarStore } from '../../products/cfar/store';

const VERIFICATION_CODE = 'verificationCode';
const CONTROL_MAX_WIDTH = [undefined, '250px'];
const VERIFICATION_CODE_PATTERN = new RegExp('^[0-9]{6,}$');

type SendCodeResponse = { anonymizedEmailAddress: string };
type CheckCodeResponse = { compliant: boolean };

type Props = {
  sendVerificationCode: UseMutationResult<SendCodeResponse, Error, void>;
  checkVerificationCode: UseMutationResult<CheckCodeResponse, Error, string>;
};

type FormState = { [VERIFICATION_CODE]: string };

export function AuthenticationForm({
  sendVerificationCode,
  checkVerificationCode,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>();
  const { t } = useTranslation();
  const codeSent = sendVerificationCode.isSuccess;
  const emailAddress = sendVerificationCode.data?.anonymizedEmailAddress || '';
  const subTitle = codeSent
    ? t('CFAR_EXERCISE_FLOW.SEND_VERIFICATION_INFO', {
        email: emailAddress,
      })
    : t('CFAR_EXERCISE_FLOW.CHECK_VERIFICATION_INFO');

  const { sessionId } = useCfarStore();
  const {
    data: { contactFormUrl },
  } = useSuspenseQuery(sessionOptions(sessionId));

  const verificationCodeError = () => {
    const requestError = checkVerificationCode.error;

    if (requestError) {
      return (
        <Trans
          i18nKey={'CFAR_EXERCISE_FLOW.CONTACT_SUPPORT_MESSAGE'}
          components={[
            <Link href={contactFormUrl} target="_blank" rel="noopener" />,
          ]}
        />
      );
    } else if (errors[VERIFICATION_CODE]) {
      return t('CFAR_EXERCISE_FLOW.FORM.VERIFICATION_CODE_ERROR');
    }

    return undefined;
  };

  const onSubmit = handleSubmit(({ verificationCode }) => {
    checkVerificationCode.mutate(verificationCode);
  });

  const verificationForm = (
    <Box
      component="form"
      onSubmit={onSubmit}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      width={'100%'}
    >
      <TextField
        inputProps={{
          'data-testid': VERIFICATION_CODE,
        }}
        {...register(VERIFICATION_CODE, {
          required: true,
          pattern: VERIFICATION_CODE_PATTERN,
        })}
        type="text"
        inputMode="numeric"
        fullWidth
        variant="outlined"
        label={t('CFAR_EXERCISE_FLOW.FORM.VERIFICATION_CODE')}
        error={!!verificationCodeError()}
        helperText={verificationCodeError()}
        sx={{ maxWidth: CONTROL_MAX_WIDTH }}
      />

      <Button
        type="submit"
        data-testid="continue"
        variant="contained"
        fullWidth
        sx={{
          maxWidth: CONTROL_MAX_WIDTH,
          bgcolor: (theme) => theme.palette.secondary.main,
        }}
        disabled={checkVerificationCode.isPending}
      >
        {t('CFAR_EXERCISE_FLOW.BUTTON.CONTINUE')}
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 0,
      }}
    >
      <Typography variant="h2">
        {t('CFAR_EXERCISE_FLOW.CHECK_VERIFICATION_TITLE')}
      </Typography>
      <Typography>{subTitle}</Typography>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        {codeSent && verificationForm}

        <FormLabel
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            alignItems: 'center',
          }}
          error={!!sendVerificationCode.isError}
        >
          <Button
            data-testid="sendCode"
            variant={codeSent ? 'text' : 'contained'}
            fullWidth
            sx={{
              maxWidth: CONTROL_MAX_WIDTH,
              bgcolor: (theme) =>
                codeSent ? 'transparent' : theme.palette.secondary.main,
            }}
            onClick={() => sendVerificationCode.mutate()}
            disabled={
              checkVerificationCode.isPending ||
              sendVerificationCode.isPending ||
              checkVerificationCode.isSuccess
            }
          >
            {codeSent
              ? t('CFAR_EXERCISE_FLOW.BUTTON.RESEND_VERIFICATION_CODE')
              : t('CFAR_EXERCISE_FLOW.BUTTON.SEND_VERIFICATION_CODE')}
          </Button>

          {sendVerificationCode.error && t('ERROR_DEFAULT')}
        </FormLabel>
      </Box>
    </Box>
  );
}
