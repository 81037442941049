import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { UseMutationResult, useSuspenseQuery } from '@tanstack/react-query';
import { Trans } from 'react-i18next';
import { sessionOptions } from '../../products/cfar/queries/sessionOptions';
import { useCfarStore } from '../../products/cfar/store';
import OTPInput, { INPUT_LENGTH } from './OTPInput';
import { useState } from 'react';
import { usePartnerTranslation } from '../../products/cfar/components/hooks/usePartnerTranslation';

const CONTROL_MAX_WIDTH = [undefined, '250px'];

type SendCodeResponse = { anonymizedEmailAddress: string };
type CheckCodeResponse = { compliant: boolean };

type Props = {
  sendVerificationCode: UseMutationResult<SendCodeResponse, Error, void>;
  checkVerificationCode: UseMutationResult<CheckCodeResponse, Error, string>;
};

export function AuthenticationFormV2({
  sendVerificationCode,
  checkVerificationCode,
}: Props) {
  const { t } = usePartnerTranslation();
  const codeSent = sendVerificationCode.isSuccess;
  const emailAddress = sendVerificationCode.data?.anonymizedEmailAddress || '';
  const subTitle = codeSent
    ? t('CFAR_EXERCISE_FLOW_V2.SEND_VERIFICATION_INFO', {
        email: emailAddress,
      })
    : t('CFAR_EXERCISE_FLOW_V2.CHECK_VERIFICATION_INFO');

  const { sessionId } = useCfarStore();
  const {
    data: { contactFormUrl },
  } = useSuspenseQuery(sessionOptions(sessionId));
  const [otp, setOtp] = useState('');

  const verificationCodeError = () => {
    if (checkVerificationCode.error) {
      return (
        <Trans
          i18nKey={'CFAR_EXERCISE_FLOW_V2.CONTACT_SUPPORT_MESSAGE'}
          components={[
            <Link href={contactFormUrl} target="_blank" rel="noopener" />,
          ]}
        />
      );
    }
    return undefined;
  };

  const onSubmit = () => {
    checkVerificationCode.mutate(otp);
  };

  const verificationForm = (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      width={'100%'}
    >
      <OTPInput onChange={setOtp} error={verificationCodeError()} />

      <Button
        data-testid="continue"
        variant="contained"
        fullWidth
        onClick={onSubmit}
        disabled={
          otp.length !== INPUT_LENGTH || checkVerificationCode.isPending
        }
        sx={{
          maxWidth: CONTROL_MAX_WIDTH,
        }}
        color="secondary"
      >
        {t('CFAR_EXERCISE_FLOW_V2.BUTTON.CONTINUE')}
      </Button>
      <Typography
        sx={{
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Trans
          i18nKey="CFAR_EXERCISE_FLOW_V2.BUTTON.RESEND_VERIFICATION_CODE"
          components={{
            button: (
              <Button
                data-testid="resendCode"
                onClick={(e) => {
                  e.preventDefault();
                  setOtp('');
                  sendVerificationCode.mutate();
                  checkVerificationCode.reset();
                }}
                sx={{
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: 1.25,
                }}
                color="secondary"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  );

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 3,
        maxWidth: [undefined, '800px'],
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          p: 0,
        }}
      >
        <Typography variant="h2">
          {t('CFAR_EXERCISE_FLOW_V2.CHECK_VERIFICATION_TITLE')}
        </Typography>
        <Typography>{subTitle}</Typography>
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          {codeSent && verificationForm}

          {!codeSent && (
            <Button
              data-testid="sendCode"
              variant={'contained'}
              fullWidth
              sx={{
                maxWidth: CONTROL_MAX_WIDTH,
              }}
              onClick={() => sendVerificationCode.mutate()}
              disabled={
                checkVerificationCode.isPending ||
                sendVerificationCode.isPending ||
                checkVerificationCode.isSuccess
              }
              color="secondary"
            >
              {t('CFAR_EXERCISE_FLOW_V2.BUTTON.SEND_VERIFICATION_CODE')}
            </Button>
          )}

          {sendVerificationCode.error && t('ERROR_DEFAULT')}
        </Box>
      </Box>
    </Paper>
  );
}
