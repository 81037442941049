import { Trans, useTranslation } from 'react-i18next';
import { ErrorCode } from '../../constants/errorCode';
import Link from '@mui/material/Link';
import { useQuery } from '@tanstack/react-query';
import { sessionOptions } from '../../../products/cfar/queries/sessionOptions';
import { useCfarStore } from '../../../products/cfar/store';
import { TFunction } from 'i18next';

export function useErrorMessage(code: ErrorCode) {
  const { t } = useTranslation();
  const { sessionId } = useCfarStore();
  const { data } = useQuery({
    ...sessionOptions(sessionId),
    throwOnError: false,
  });
  switch (code) {
    case ErrorCode.NotAuthorized:
      return t('SESSION_EXPIRED_DIALOG.DESCRIPTION');
    case ErrorCode.ContactSupport: {
      return getContactSupportMessage(
        data?.contactFormUrl,
        'CFAR_EXERCISE_FLOW.CONTACT_SUPPORT_MESSAGE',
        t
      );
    }
    case ErrorCode.ExerciseFailed: {
      return getContactSupportMessage(
        data?.contactFormUrl,
        'ERROR_EXERCISE_FAILED',
        t
      );
    }
    case ErrorCode.UrgentContactSupport:
      return t('CFAR_EXERCISE_FLOW.URGENT_CONTACT_SUPPORT');
    case ErrorCode.InvalidSearchParams:
      return t('HOME.INVALID_PARAMETERS');
    case ErrorCode.ExerciseAlreadyHasATransaction:
      return getContactSupportMessage(
        data?.contactFormUrl,
        'CFAR_EXERCISE_FLOW.CONTRACT_ALREADY_REFUNDED',
        t
      );
    case ErrorCode.Generic:
    default:
      return t('ERROR_DEFAULT');
  }
}

const getContactSupportMessage = (
  contactUrl: string | undefined,
  errorKey: string,
  t: TFunction
) => {
  if (!contactUrl) return t('ERROR_DEFAULT');
  return (
    <Trans
      i18nKey={errorKey}
      t={t}
      components={[<Link href={contactUrl} target="_blank" rel="noopener" />]}
    />
  );
};
