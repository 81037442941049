import { Partner } from '../constants/partner';

const PartnerIconNames = new Map<string, string>([
  [Partner.HtsAirline, 'default'],
  [Partner.VirginAustralia, 'va'],
  [Partner.Volaris, 'y4'],
  [Partner.Flair, 'f8'],
  [Partner.AirCanada, 'ac'],
  [Partner.AirAsia, 'ak'],
  [Partner.Jazeera, 'j9'],
]);

function getPartnerLogoUrl(partnerCode: string) {
  let extension = '.svg';
  const logoPath = `../../assets/logos/logo-${partnerCode}`;

  switch (partnerCode) {
    case PartnerIconNames.get(Partner.Jazeera):
      extension = '.png';
      break;
  }

  return new URL(logoPath + extension, import.meta.url).href;
}

export function iconForPartner(partnerId: string) {
  const partnerCode = PartnerIconNames.get(partnerId) || 'default';
  return getPartnerLogoUrl(partnerCode);
}
