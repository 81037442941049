import { useCallback, useEffect, useState } from 'react';
import { useDynamicScript } from './useDynamicScript';
import { ErrorCode } from '../../constants/errorCode';
import { Logger } from '../../../products/cfar/utilities/logger';

export type HWTemplate = 'bootstrap3' | 'plain';

export enum HWTransferMethodStatus {
  ACTIVATED,
  INVALID,
  DE_ACTIVATED,
}
export enum HWTransferMethodType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_CARD = 'BANK_CARD',
  PAPER_CHECK = 'PAPER_CHECK',
  PAYPAL_ACCOUNT = 'PAYPAL_ACCOUNT',
  PREPAID_CARD = 'PREPAID_CARD',
  VENMO_ACCOUNT = 'VENMO_ACCOUNT',
  WIRE_ACCOUNT = 'WIRE_ACCOUNT',
}

export type HWTransferMethod = {
  createdOn: string;
  email: string;
  status: HWTransferMethodStatus;
  token: string;
  transferMethodCountry: string;
  transferMethodCurrency: string;
  type: HWTransferMethodType;
  userToken: string;
};

export type HWUsersConfigureArgs = {
  template?: HWTemplate;
  el: HTMLElement | null;

  onSuccess?: (authToken: string) => void;
  onFailure?: () => void;
};

export type HWTransferMethodsConfigureArgs = {
  template?: HWTemplate;
  el: HTMLElement | null;
  data?: any;
  skipProfileFields?: boolean;
  transferMethodConfiguration?: any;

  onBeforeSubmit?: () => void;
  onSubmit?: (state: any) => void;
  onComplete?: (trmObject: any, completionResult: string) => void;
  onLoading?: (toggle: boolean) => void;
};

// https://docs.hyperwallet.com/content/drop-in-uis/v2_4/transfer-method/transfer-methods/create-transfer-method
export type HWCreateConfig = {
  country?: string;
  currency?: string;
  profileType: 'INDIVIDUAL' | 'BUSINESS';
};

export type HWConfigureReturnArgs = {
  create: (config: HWCreateConfig) => void;
  destroy: (callback?: () => void) => void;
  enable: (callback?: () => void) => void;
  disable: (callback?: () => void) => void;
  addError: (callback?: () => void) => void;
  clearErrors: (callback?: () => void) => void;
};

export type HWWidgets = {
  initialize: (onInitialize: (onSuccess: any, onFailure: any) => void) => void;
  transferMethods: {
    configure: (args: HWTransferMethodsConfigureArgs) => HWConfigureReturnArgs;
  };
  users: {
    configure: (args: HWUsersConfigureArgs) => HWConfigureReturnArgs;
  };
  events: any;
};

declare global {
  interface Window {
    HWWidgets: HWWidgets;
  }
}

export const useHyperWallet = (
  userToken: string,
  authToken: string,
  language = 'en'
): boolean => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [, setError] = useState();
  const languageCode = language.slice(0, 2);

  const scriptUrl = `${
    import.meta.env.VITE_HYPERWALLET_WIDGET_URL
  }${userToken}/${languageCode}.v2_4_5.min.js`;

  const { ready } = useDynamicScript(scriptUrl);

  const onInitialized = useCallback(
    (onSuccess: any) => {
      if (authToken) {
        onSuccess(authToken);
      }
    },
    [authToken]
  );

  useEffect(() => {
    if (userToken && ready && !initialized) {
      try {
        window.HWWidgets.initialize(onInitialized);
        setInitialized(true);
      } catch (e: any) {
        // TODO product-agnostic logging
        Logger.error('hyperwallet initialization error', {}, e);
        setError(() => {
          throw Error(ErrorCode.ContactSupport);
        });
      }
    }
  }, [userToken, ready, initialized, onInitialized]);

  return initialized;
};
