import {
  Typography,
  Paper,
  Box,
  Container,
  Divider,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import {
  createRootRouteWithContext,
  ErrorComponentProps,
  Outlet,
} from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { LanguageMenu } from '../common/components/LanguageMenu';
import { Footer } from '../common/components/Footer';
import { QueryClient } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { ExitToApp } from '@mui/icons-material';
import { usePartnerRedirect } from '../products/cfar/components/hooks/usePartnerRedirect';
import {
  HEADER_FOOTER_HEIGHT,
  VIEWPORT_HEIGHT,
} from '../common/constants/layout';
import { ErrorCode, toErrorCode } from '../common/constants/errorCode';
import { ErrorDisplay } from '../common/components/ErrorDisplay';
import { useEffect, useState } from 'react';
import { Partner } from '../common/constants/partner';
import { themeForPartnerId } from '../common/utilities/themeForPartnerId';
import { iconForPartner } from '../common/utilities/iconForPartner';
import {
  setExerciseId,
  setPartnerId,
  setSessionId,
  useCfarStore,
} from '../products/cfar/store';
import { usePartnerTranslation } from '../products/cfar/components/hooks/usePartnerTranslation';
import createCache from '@emotion/cache';
import { useFeatureFlag } from '../products/cfar/components/hooks/useFeatureFlag';
import { FeatureFlag } from '../products/cfar/featureFlags';
import { PartnerXHtsLogo } from '../products/cfar/components/PartnerXHtsLogo';

import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { createExercise } from '../products/cfar/api/createExercise';

const ltrCache = createCache({
  key: 'mui',
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const initializeExercise = async (contractId: string) => {
  const { sessionId, exerciseId } = await createExercise({
    contract_id: contractId,
  });
  setSessionId(sessionId);
  setExerciseId(exerciseId);
};

const RootComponent = () => {
  const { t, i18n } = usePartnerTranslation();
  const { partnerId, sessionId } = useCfarStore(({ partnerId, sessionId }) => ({
    partnerId,
    sessionId,
  }));

  const useExerciseRedesign = useFeatureFlag(
    sessionId,
    FeatureFlag.CfarUseExerciseRedesign
  );

  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const [theme, setTheme] = useState({
    ...themeForPartnerId(partnerId),
    direction,
  });

  useEffect(() => {
    document.documentElement.setAttribute('dir', direction);
    setTheme({
      ...themeForPartnerId(partnerId, useExerciseRedesign),
      direction,
    });
  }, [partnerId, direction, useExerciseRedesign]);

  const partnerRedirect = usePartnerRedirect();
  const cacheValue = direction === 'rtl' ? cacheRtl : ltrCache;

  //todo: https://hopper-jira.atlassian.net/browse/HTSFA-1982 cleanup these two variables after all partners are using the new design
  const heightOffsetMultiplier = useExerciseRedesign ? 1 : 2;
  const xsScreenPadding = useExerciseRedesign ? 1 : 0;

  return (
    <CacheProvider value={cacheValue}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            height: VIEWPORT_HEIGHT,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: useExerciseRedesign ? '#f2f2f2' : '#fafafa',
          }}
        >
          <Box
            component="nav"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              flexShrink: 0, // prevents nav from shrinking cause of flex
              height: HEADER_FOOTER_HEIGHT,
              px: [0, 4],
              width: '100%',
              borderRadius: 0,
              bgcolor: (theme) =>
                useExerciseRedesign
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              //todo: https://hopper-jira.atlassian.net/browse/HTSFA-1982 remove conditional after all partners are using the new design
              boxShadow: useExerciseRedesign
                ? '0px 2px 12px 0px #50505026;'
                : '',
            }}
          >
            {useExerciseRedesign ? (
              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
                flexDirection="row"
                gap={2}
              >
                <PartnerXHtsLogo partnerId={partnerId} />
              </Box>
            ) : (
              //todo: https://hopper-jira.atlassian.net/browse/HTSFA-1982 remove conditional after all partners are using the new design
              <Box display="flex" flexDirection="row">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxHeight: '48px',
                  }}
                  onError={(e) => (e.currentTarget.style.display = 'none')}
                  src={iconForPartner(partnerId)}
                />
              </Box>
            )}

            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
              <LanguageMenu />
            </Box>
          </Box>

          <Container
            maxWidth={false}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: `calc(${VIEWPORT_HEIGHT} - (${heightOffsetMultiplier} * ${HEADER_FOOTER_HEIGHT}))`,
              padding: [xsScreenPadding, 2, 4],
              overflowY: 'auto',
              scrollbarGutter: 'stable', // todo do we need this?
            }}
          >
            {useExerciseRedesign ? (
              <Outlet />
            ) : (
              <Paper
                elevation={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                  p: 3,
                  maxWidth: [undefined, '800px'],
                }}
              >
                <>
                  <Typography textAlign="center" variant="h1">
                    {t('CFAR_EXERCISE_FLOW.TITLE')}
                  </Typography>
                  <Divider />
                </>
                <Outlet />
              </Paper>
            )}

            {!useExerciseRedesign && partnerRedirect && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                p={2}
              >
                <Button
                  variant="text"
                  sx={{
                    textTransform: 'none',
                    gap: 1,
                  }}
                  onClick={partnerRedirect.handler}
                  data-testid="redirectBack"
                  color={'secondary'}
                >
                  <ExitToApp />
                  {t('CFAR_EXERCISE_FLOW.BUTTON.GO_BACK_TO_PARTNER', {
                    partnerName: partnerRedirect.partnerName,
                  })}
                </Button>
              </Box>
            )}
          </Container>

          {!useExerciseRedesign && <Footer />}

          {import.meta.env.DEV && (
            <>
              <FormControl
                sx={{
                  display: 'flex',
                }}
              >
                <Select
                  id="theme-selector"
                  value={partnerId}
                  onChange={(e) => {
                    setPartnerId(e.target.value as Partner);
                  }}
                >
                  {Object.entries(Partner).map(([value, key]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TanStackRouterDevtools />
            </>
          )}
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const errorCode = toErrorCode(error.message);
  return <ErrorDisplay errorCode={errorCode} />;
};

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  beforeLoad: async () => {
    const { sessionId, exerciseId, contractId } = useCfarStore.getState();

    // If we have a sessionId and exerciseId, we don't need to initialize the exercise
    if (sessionId && exerciseId) return;

    if (!contractId) throw new Error(ErrorCode.InvalidSearchParams);

    try {
      await initializeExercise(contractId);
    } catch (error) {
      throw new Error(ErrorCode.ContactSupport);
    }
  },
  errorComponent: ErrorComponent,
  component: RootComponent,
});
