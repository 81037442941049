import i18next, { changeLanguage } from 'i18next';
import { retrieveSession } from '../api/retrieveSession';
import { DEFAULT_LNG, SupportedLocale } from '../../../i18n/constants';
import { userLanguage } from '../utilities/userLanguage';
import { FIFTEEN_MINUTES } from './constants';
import { setDatadogContext } from '../utilities/setDatadogContext';
import { HEADER_HTS_EXPERIMENTS } from '../../../common/constants/http';
import { Partner } from '../../../common/constants/partner';
import { setPartnerId } from '../store';
import { queryOptions } from '@tanstack/react-query';

export const sessionOptions = (sessionId: string) =>
  queryOptions({
    enabled: () => !!sessionId,
    queryKey: ['session'],
    queryFn: async () => {
      const response = await retrieveSession(sessionId);
      const experiments: Record<string, boolean> = JSON.parse(
        response.raw.headers.get(HEADER_HTS_EXPERIMENTS) || '{}'
      );
      const {
        contactFormUrl: contactFormUrlMap,
        language = SupportedLocale.EN,
        pointOfSale,
        partnerId: partner,
        partnerName,
      } = await response.value();
      const sessionLanguage = language.toUpperCase();
      const contactFormUrl = contactFormUrlMap[sessionLanguage]
        ? contactFormUrlMap[sessionLanguage]
        : contactFormUrlMap[DEFAULT_LNG];
      const nextLanguage = userLanguage(sessionLanguage, pointOfSale);

      if (nextLanguage !== i18next.language) {
        changeLanguage(nextLanguage);
      }

      const partnerId = partner as Partner;

      setDatadogContext({
        session_id: sessionId,
        language: nextLanguage,
        partner_id: partnerId,
        partner_name: partnerName,
      });

      setPartnerId(partnerId);

      return { partnerId, partnerName, contactFormUrl, experiments };
    },
    staleTime: FIFTEEN_MINUTES,
  });
