import { useSuspenseQuery } from '@tanstack/react-query';
import { FeatureFlag } from '../../featureFlags';
import { sessionOptions } from '../../queries/sessionOptions';

export function useFeatureFlag(sessionId: string, featureFlag: FeatureFlag) {
  const {
    data: { experiments },
  } = useSuspenseQuery(sessionOptions(sessionId));
  return experiments[featureFlag];
}
