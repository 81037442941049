import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const j9PrimaryPalette = {
  50: '#e1eef8',
  100: '#b3d5ec',
  200: '#81b9e0',
  300: '#4e9dd4',
  400: '#2888ca',
  500: '#0273c1',
  600: '#026bbb',
  700: '#0160b3',
  800: '#0156ab',
  900: '#01439e',
  A100: '#cce9ff',
  A200: '#99d3ff',
  A400: '#66bdff',
  A700: '#4cb2ff',
};

const j9AccentPalette = {
  50: '#fff8e1',
  100: '#ffefb3',
  200: '#ffe481',
  300: '#ffd94f',
  400: '#ffd029',
  500: '#ffc803',
  600: '#ffc203',
  700: '#ffbb02',
  800: '#ffb402',
  900: '#ffa701',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#fff2e8',
};

export const j9Theme = responsiveFontSizes(
  createTheme({
    cssVariables: { cssVarPrefix: '' },

    typography: {
      ...typographyDefaults,
      h2: {
        fontFamily: 'Avenir',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: 1.25,
      },
      body2: {
        ...typographyDefaults.body1,
        fontFamily: 'Avenir',
      },
      fontFamily: 'Avenir',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: j9PrimaryPalette[400],
            light: j9PrimaryPalette[500],
            dark: j9PrimaryPalette[300],
            contrastText: 'white',
          },
          secondary: {
            main: j9AccentPalette[500],
            light: j9AccentPalette[300],
            dark: j9AccentPalette[700],
            contrastText: 'black',
          },
          error: {
            main: '#f23221',
            light: '#e66b67',
            dark: '#dd2825',
          },
        },
      },
    },
    components: {
      ...componentDefaults,
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            lineHeight: 1.25,
            borderRadius: '4px',
            height: '48px',
            textTransform: 'none',
            fontFamily: 'Avenir',
          },
        },
      },
    },
  })
);
