import { RumInitConfiguration } from '@datadog/browser-rum';
import { CURRENT_ENVIRONMENT, DD_SERVICE, VERSION } from '../../../config';

const RUM_OPTIONS: RumInitConfiguration = {
  applicationId: 'd8c5fb52-8cff-4d84-87c3-3bf888191920',
  clientToken: 'pubac6e26c8032c75258a493c7237e42cdd',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'us5.datadoghq.com',
  service: DD_SERVICE,
  env: CURRENT_ENVIRONMENT,
  version: VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [],
};

export async function startRumSession() {
  const { datadogRum } = await import('@datadog/browser-rum');

  datadogRum.init(RUM_OPTIONS);
  datadogRum.startSessionReplayRecording();
}
