import { Box, CircularProgress } from '@mui/material';

export function Loading() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      alignItems="center"
      justifyContent="center"
      minHeight={'100px'}
    >
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.secondary.main,
        }}
      />
    </Box>
  );
}
