import { Logger } from '../../../products/cfar/utilities/logger';
import { useCallback, useEffect, useState } from 'react';
import { ErrorCode } from '../../constants/errorCode';

export const useDynamicScript = (
  src: string
): { el?: HTMLScriptElement; ready: boolean } => {
  const [ready, setReady] = useState<boolean>(false);
  const [script, setScript] = useState<HTMLScriptElement>();
  const [, setError] = useState();

  const onLoad = useCallback(() => {
    setReady(true);
  }, []);

  const onError = useCallback((e: any) => {
    // TODO find alternative to using cfar-specific logger inside common
    Logger.error('Error loading Hyperwallet script', {}, e);
    setError(() => {
      throw Error(ErrorCode.ContactSupport);
    });
  }, []);

  useEffect(() => {
    if (src) {
      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.async = true;
      scriptEl.src = src;

      scriptEl.onload = onLoad;
      scriptEl.onerror = onError;

      setScript(scriptEl);

      document.head.appendChild(scriptEl);

      return () => {
        if (scriptEl) {
          document.head.removeChild(scriptEl);
        }
      };
    }
  }, [src, onError, onLoad]);

  return { el: script, ready };
};
