import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const acPrimaryPalette = {
  50: '#e0e0e0',
  100: '#b3b3b3',
  200: '#808080',
  300: '#ffffff',
  400: '#262626',
  500: '#4a4f55',
  600: '#000000',
  700: '#000000',
  800: '#000000',
  900: '#000000',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666',
};

const acAccentPalette = {
  50: '#f4e5f0',
  100: '#e3bfda',
  200: '#d094c2',
  300: '#bd69aa',
  400: '#af4897',
  500: '#005078',
  600: '#99247d',
  700: '#8f1e72',
  800: '#851868',
  900: '#740f55',
  A100: '#ffa7e2',
  A200: '#ff74d0',
  A400: '#ff41bf',
  A700: '#ff28b7',
};

export const acTheme = responsiveFontSizes(
  createTheme({
    cssVariables: { cssVarPrefix: '' },

    typography: {
      ...typographyDefaults,
      h2: {
        ...typographyDefaults.h2,
        fontSize: '18px',
        lineHeight: 1.25,
        fontFamily: 'Open-Sans-Bold',
      },
      body2: {
        ...typographyDefaults.body1,
        fontFamily: 'Open-Sans-Bold',
      },
      fontFamily: 'Open-Sans-Regular',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: acPrimaryPalette[500],
            light: acPrimaryPalette[300],
            dark: acPrimaryPalette[700],
            contrastText: 'black',
          },
          secondary: {
            main: acAccentPalette[500],
            light: acAccentPalette[300],
            dark: acAccentPalette[700],
            contrastText: 'white',
          },
          error: {
            main: '#f23221',
            light: '#e66b67',
            dark: '#dd2825',
          },
        },
      },
    },
    components: {
      ...componentDefaults,
      MuiButton: {
        styleOverrides: {
          root: {
            height: '48px',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: 1.25,
            borderRadius: '14px',
            textTransform: 'none',
            fontFamily: 'Open-Sans-Bold',
          },
        },
      },
    },
  })
);
