import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { componentDefaults, typographyDefaults } from './constants';

const y4PrimaryPalette = {
  50: '#e0e0e0',
  100: '#b3b3b3',
  200: '#808080',
  300: '#000000',
  400: '#262626',
  500: '#000000',
  600: '#000000',
  700: '#000000',
  800: '#000000',
  900: '#000000',
  A100: '#a6a6a6',
  A200: '#8c8c8c',
  A400: '#737373',
  A700: '#666666',
};

const y4AccentPalette = {
  50: '#f4e5f0',
  100: '#e3bfda',
  200: '#d094c2',
  300: '#bd69aa',
  400: '#af4897',
  500: '#a12885',
  600: '#99247d',
  700: '#8f1e72',
  800: '#851868',
  900: '#740f55',
  A100: '#ffa7e2',
  A200: '#ff74d0',
  A400: '#ff41bf',
  A700: '#ff28b7',
};

export const y4Theme = responsiveFontSizes(
  createTheme({
    cssVariables: { cssVarPrefix: '' },

    typography: {
      ...typographyDefaults,
      h2: {
        fontSize: '18px',
        lineHeight: 1.25,
        fontFamily: 'Avenir-LT',
        fontWeight: 700,
      },
      body2: {
        ...typographyDefaults.body1,
        fontFamily: 'Avenir-LT-Bold',
      },
      fontFamily: 'Avenir-LT',
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: y4PrimaryPalette[500],
            light: y4PrimaryPalette[300],
            dark: y4PrimaryPalette[700],
            contrastText: 'white',
          },
          secondary: {
            main: y4AccentPalette[500],
            light: y4AccentPalette[300],
            dark: y4AccentPalette[700],
            contrastText: 'white',
          },
          error: {
            main: '#f23221',
            light: '#e66b67',
            dark: '#dd2825',
          },
        },
      },
    },
    components: {
      ...componentDefaults,
    },
  })
);
